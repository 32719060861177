export default {
  headers: [
    {
      id: 'notice-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/susak/logo.jpg',
        url: 'https://www.ryanandbrian.com/',
      },
      breakpoint: 0,
      desktopMenu: {
        type: 'center', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-susak-nheader',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-susak-nheader-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'notice-item',
            content: 'Texas Real Estate Commission Consumer Protection Notice',
            url: 'https://www.ryanandbrian.com/texas-consumer-protection-notice/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
    {
      id: 'mainHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/susak/logo-footer.png',
        url: 'https://www.ryanandbrian.com/',
      },
      breakpoint: 959,
      desktopMenu: {
        type: 'center',
        disableOnHoverMenuExpand: true,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-susak-header',
        headerSectionsClassName: 'on-susak-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-susak-item',
        menuClassName: 'on-susak-menu',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-susak-menu-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'home-item',
            content: 'Home',
            url: 'https://www.ryanandbrian.com/',
          },
          {
            id: 'search-item',
            content: 'Search',
            menuItems: [
              {
                id: 'search-item-listings',
                content: 'Our Listings',
                url: 'https://www.ryanandbrian.com/results-gallery/?OfficeMLS=795',
              },
              {
                id: 'search-item-map',
                content: 'Map',
                url: 'https://www.ryanandbrian.com/results-map/',
              },
            ],
          },
          {
            id: 'buy-item',
            content: 'Buy',
            menuItems: [
              {
                id: 'buy-item-buy',
                content: 'Buy',
                url: 'https://www.ryanandbrian.com/buy/',
              },
              {
                id: 'buy-item-cash-offer',
                content: 'Cash Offer',
                url: 'https://cashoffer.ryanandbrian.com',
              },
            ],
          },
          {
            id: 'sell-item',
            content: 'Sell',
            menuItems: [
              {
                id: 'sell-item-sold',
                content: '956 Homes Sold',
                url: 'https://www.956homesold.com/',
              },
              {
                id: 'sell-item-guaranteed',
                content: 'Guaranteed Sell Program',
                url: 'https://www.ryanandbrian.com/home/guaranteed-sales-program/',
              },
              {
                id: 'sell-item-home-worth',
                content: 'What Is My Home Worth?',
                url: 'http://www.rgvfreehomevaluation.com/',
              },
              {
                id: 'sell-item-trade-in',
                content: 'Trade In',
                url: 'https://trade-in.ryanandbrian.com',
              },
            ],
          },
          {
            id: 'refer-item',
            content: 'Refer',
            url: 'https://www.ryanandbrian.com/referrnbteam/',
          },
          {
            id: 'team-item',
            content: 'Our Team',
            url: 'https://www.ryanandbrian.com/meet-our-team/',
          },
          {
            id: 'careers-item',
            content: 'Careers',
            menuItems: [
              {
                id: 'careers-item-956',
                content: '956 Real Estate Careers',
                url: 'https://www.ryanandbrian.com/careers/',
              },
              {
                id: 'careers-item-blog',
                content: 'Success Blog',
                url: 'http://ryanandbriancareers.blogspot.com/',
              },
            ],
          },
          {
            id: 'advice-item',
            content: 'Expert Advice',
            menuItems: [
              {
                id: 'advice-item-testimonials',
                content: 'Testimonials',
                url: 'https://www.ryanandbrian.com/testimonials/',
              },
              {
                id: 'advice-item-trends',
                content: 'Market Trends',
                url: 'https://www.ryanandbrian.com/market-trends/',
              },
              {
                id: 'advice-item-blog',
                content: 'Our Blog',
                url: 'http://ryanandbrian.blogspot.com/',
              },
              {
                id: 'advice-item-bestseller',
                content: 'Bestseller',
                url: 'https://www.ryanandbrian.com/bestseller/',
              },
            ],
          },
          {
            id: 'vendors-item',
            content: 'Vendors',
            url: 'https://www.ryanandbrian.com/vendors-the-ryan-brian-team-use-trust/',
          },
          {
            id: 'finance-item',
            content: 'Finance',
            url: 'https://www.ryanandbrian.com/finance/',
          },
          {
            id: 'contact-item',
            content: 'Contact Us',
            url: 'https://www.ryanandbrian.com/contactus/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-susak-mobile-header-logo-container',
          imageClassName: 'on-susak-mobile-header-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          className: 'on-susak-hamburger',
          hide: false,
          type: 'hamburger',
          reverse: false,
        },
        headerClassName: 'on-susak-mobile-header',
        headerClassNameOverride: '',
        mobileMenuClassName: 'on-susak-mobile-menu',
        itemClassName: 'on-susak-mobile-item',
        menuClassName: 'on-susak-mobile-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-susak-mobile-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'home-item',
            content: 'Home',
            url: 'https://www.ryanandbrian.com/',
          },
          {
            id: 'search-item',
            content: 'Search',
            menuItems: [
              {
                id: 'search-item-listings',
                content: 'Our Listings',
                url: 'https://www.ryanandbrian.com/results-gallery/?OfficeMLS=795',
              },
              {
                id: 'search-item-map',
                content: 'Map',
                url: 'https://www.ryanandbrian.com/results-map/',
              },
            ],
          },
          {
            id: 'buy-item',
            content: 'Buy',
            menuItems: [
              {
                id: 'buy-item-buy',
                content: 'Buy',
                url: 'https://www.ryanandbrian.com/buy/',
              },
              {
                id: 'cash-offer-item',
                content: 'Cash Offer',
                url: 'https://cashoffer.ryanandbrian.com',
              },
            ],
          },
          {
            id: 'sell-item',
            content: 'Sell',
            menuItems: [
              {
                id: 'sell-item-sold',
                content: '956 Homes Sold',
                url: 'https://www.956homesold.com/',
              },
              {
                id: 'sell-item-guaranteed',
                content: 'Guaranteed Sell Program',
                url: 'https://www.ryanandbrian.com/home/guaranteed-sell-program/',
              },
              {
                id: 'sell-item-home-worth',
                content: 'What Is My Home Worth?',
                url: 'http://www.rgvfreehomevaluation.com/',
              },
              {
                id: 'trade-in-item',
                content: 'Trade In',
                url: 'https://trade-in.ryanandbrian.com',
              },
            ],
          },
          {
            id: 'refer-item',
            content: 'Refer',
            url: 'https://www.ryanandbrian.com/referrnbteam/',
          },
          {
            id: 'team-item',
            content: 'Our Team',
            url: 'https://www.ryanandbrian.com/meet-our-team/',
          },
          {
            id: 'careers-item',
            content: 'Careers',
            menuItems: [
              {
                id: 'careers-item-956',
                content: '956 Real Estate Careers',
                url: 'https://www.ryanandbrian.com/careers/',
              },
              {
                id: 'careers-item-blog',
                content: 'Success Blog',
                url: 'http://ryanandbriancareers.blogspot.com/',
              },
            ],
          },
          {
            id: 'advice-item',
            content: 'Expert Advice',
            menuItems: [
              {
                id: 'advice-item-testimonials',
                content: 'Testimonials',
                url: 'https://www.ryanandbrian.com/testimonials/',
              },
              {
                id: 'advice-item-trends',
                content: 'Market Trends',
                url: 'https://www.ryanandbrian.com/market-trends/',
              },
              {
                id: 'advice-item-blog',
                content: 'Our Blog',
                url: 'http://ryanandbrian.blogspot.com/',
              },
              {
                id: 'advice-item-bestseller',
                content: 'Bestseller',
                url: 'https://www.ryanandbrian.com/bestseller/',
              },
            ],
          },
          {
            id: 'vendors-item',
            content: 'Vendors',
            url: 'https://www.ryanandbrian.com/vendors-the-ryan-brian-team-use-trust/',
          },
          {
            id: 'finance-item',
            content: 'Finance',
            url: 'https://www.ryanandbrian.com/finance/',
          },
          {
            id: 'contact-item',
            content: 'Contact Us',
            url: 'https://www.ryanandbrian.com/contactus/',
          },
        ],
      },
    },
    {
      id: 'logo-header',
      logo: {
        alt: 'logo',
        // src: 'https://si-homelight.s3.amazonaws.com/sites/susak/logo.jpg',
        src: 'https://boomtown-production-consumer-backup.s3.amazonaws.com/488/files/2020/12/home-retina.png',
        url: 'https://www.ryanandbrian.com/',
      },
      breakpoint: 959,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: 'on-susak-lheader-logo',
        },
        headerClassName: '',
        headerSectionsClassName: 'on-susak-lheader-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
  ],
  footers: [
    {
      id: 'on-susak-footer-first',
      display: true,
      className: 'on-susak-footer-section pt-2',
      sectionClassName: 'on-susak-footer-section-container',
      columns: [
        {
          id: 'col-one',
          className:
            'on-susak-footer-column on-susak-footer-column-one align-left col-xl-2 col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-one-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'Guides',
            },
            {
              id: 'col-one-menu',
              type: 'menu',
              className: 'on-susak-footer-menu',
              itemClassName: 'on-susak-footer-menu-item',
              style: {},
              items: [
                {
                  id: 'col-one-item-one',
                  data: 'McAllen',
                  url: 'https://www.ryanandbrian.com/guide/mcallen//',
                },
                {
                  id: 'col-one-item-two',
                  data: 'Mission',
                  url: 'https://www.ryanandbrian.com/guide/mission/',
                },
                {
                  id: 'col-one-item-three',
                  data: 'Edinburg',
                  url: 'https://www.ryanandbrian.com/guide/edinburg/',
                },
                {
                  id: 'col-one-item-four',
                  data: 'Pharr',
                  url: 'https://www.ryanandbrian.com/guide/pharr/',
                },
                {
                  id: 'col-one-item-five',
                  data: 'Luxury Homes',
                  url: 'https://www.ryanandbrian.com/guide/luxury-homes/',
                },
                {
                  id: 'col-one-item-six',
                  data: 'More Guides >',
                  url: 'https://www.ryanandbrian.com/guides/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-two',
          className: 'on-susak-footer-column align-left col-xl-2 col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'Company',
            },
            {
              id: 'col-two-menu',
              type: 'menu',
              className: 'on-susak-footer-menu',
              style: {},
              items: [
                {
                  id: 'col-two-item-one',
                  data: 'Meet Our Agents',
                  url: 'https://www.ryanandbrian.com/agents/',
                },
                {
                  id: 'col-two-item-two',
                  data: 'Our Blog',
                  url: 'http://www.ryanandbrian.blogspot.com/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-three',
          className: 'on-susak-footer-column align-left col-xl-2 col-lg-2 col-md-3 col-sm-24',
          style: {},
          items: [
            {
              id: 'col-three-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'Resources',
            },
            {
              id: 'col-three-menu',
              type: 'menu',
              className: 'on-susak-footer-menu',
              style: {},
              items: [
                {
                  id: 'col-three-item-one',
                  data: 'Buy a Home',
                  url: 'https://www.ryanandbrian.com/buy/',
                },
                {
                  id: 'col-three-item-two',
                  data: 'Sell Your Home',
                  url: 'https://www.ryanandbrian.com/sell/',
                },
                {
                  id: 'col-three-item-three',
                  data: 'Finance',
                  url: 'https://www.ryanandbrian.com/finance/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-four',
          className: 'on-susak-footer-column align-left col-xl-2 col-lg-2 col-md-3 col-sm-24',
          items: [
            {
              id: 'col-four-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'Get Social',
            },
            {
              id: 'col-four-socials',
              type: 'socialIcons',
              className: 'on-susak-footer-socials',
              itemClassName: 'on-susak-footer-socials-item',
              style: {},
              items: [
                {
                  id: 'col-four-twitter',
                  className: 'on-footer-susak-twitter on-footer-susak-soc',
                  iconTypeClassName: 'fab',
                  iconClassName: 'twitter',
                  url: 'http://twitter.com/RnBTeam',
                },
                {
                  id: 'col-four-facebook',
                  className: 'on-footer-susak-face on-footer-susak-soc',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  url: 'http://facebook.com//rnbrealestate?fref=ts',
                },
                {
                  id: 'col-four-linked',
                  className: 'on-footer-susak-linked on-footer-susak-soc',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                  url: 'http://www.linkedin.com/in/brianreedrnbteam',
                },
                {
                  id: 'col-four-pinterest',
                  className: 'on-footer-susak-pint on-footer-susak-soc',
                  iconTypeClassName: 'fab',
                  iconClassName: 'pinterest-p',
                  url: 'http://pinterest.com//rnbteam/',
                },
                {
                  id: 'col-four-youtube',
                  className: 'on-footer-susak-youtube on-footer-susak-soc',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                  url: 'https://www.youtube.com/channel/UCyGgHjt3e7J4LwsI8X-G9Sw/feed',
                },
              ],
            },
          ],
        },
        {
          id: 'col-five',
          className: 'on-susak-footer-column align-left col-xl-4 col-lg-4 col-sm-24 pt-md-5 pt-lg-1 pt-xl-1',
          style: {},
          items: [
            {
              id: 'col-five-heading-one',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'About Us',
            },
            {
              id: 'col-five-heading-two',
              type: 'heading',
              elementType: 'p',
              className: 'pb-1',
              style: {},
              data: "The Ryan & Brian Real Estate Team is Hidalgo County's most innovative real estate team.",
            },
            {
              id: 'col-five-logo',
              type: 'image',
              className: 'col-five-logo',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/susak/logo-footer-tierra-frontera.png',
            },
            {
              id: 'col-five-heading-three',
              type: 'heading',
              elementType: 'p',
              className: '',
              style: {},
              data: 'The Ryan & Brian Real Estate Team',
            },
            {
              id: 'col-five-heading-four',
              type: 'heading',
              elementType: 'p',
              className: '',
              style: {},
              data: 'Tierra Frontera, LLC',
            },
            {
              id: 'col-five-heading-five',
              type: 'heading',
              elementType: 'p',
              className: '',
              style: {},
              data: '801 E. Fern Avenue, Ste. 113, McAllen, TX 78501',
            },
            {
              type: 'itemContent',
              className: 'mb-0 pt-sm-4 on-susak-num-one',
              style: {},
              items: [
                {
                  content: 'Office: ',
                },
                {
                  content: '956-203-0044',
                  url: 'tel:956-203-0044',
                  className: '',
                },
              ],
            },
            {
              type: 'itemContent',
              className: 'mb-0 pt-sm-4 on-susak-num-two',
              style: {},
              items: [
                {
                  content: 'Buyer Expert: ',
                },
                {
                  content: '956-998-4738',
                  url: 'tel:956-998-4738',
                  className: '',
                },
              ],
            },
            {
              type: 'itemContent',
              className: 'mb-0 pt-sm-4 on-susak-num-three',
              style: {},
              items: [
                {
                  content: 'Seller Expert: ',
                },
                {
                  content: '956-948-4128',
                  url: 'tel:956-948-4128',
                  className: '',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-susak-footer-second',
      display: true,
      className: 'on-susak-footer-section',
      sectionClassName: 'on-susak-footer-section-container',
      columns: [
        {
          id: 'disclaimer-column',
          className: 'on-susak-footer-row-disclaimer align-left pt-3',
          style: {},
          items: [
            {
              id: 'disclaimer-copyright',
              type: 'heading',
              className: 'on-susak-disclaimer',
              style: {},
              data: '© 2022 Greater McAllen Association of Realtors Multiple Listing Service.',
            },
            {
              id: 'disclaimer-data',
              type: 'heading',
              className: 'on-susak-disclaimer',
              style: {},
              data: "IDX information is provided exclusively for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Data is deemed reliable but is not guaranteed accurate by the MLS or The Ryan & Brian Real Estate Team | Tierra Frontera, LLC. Data last updated: 2022-05-31T00:16:53.673.© 2022 Harlingen Board of Realtors Multiple Listing Service. All rights reserved. IDX information is provided exclusively for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Information is deemed reliable but is not guaranteed accurate by the MLS or The Ryan & Brian Real Estate Team | Tierra Frontera, LLC. Data last updated: 2022-05-30T23:51:14.793.",
            },
            {
              id: 'footer-two-logo',
              type: 'image',
              className: 'footer-two-logo pt-2 pb-2',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/susak/logo-footer.png',
              style: { maxWidth: '100%' },
            },
            {
              id: 'footer-two-notice',
              type: 'heading',
              url: 'https://www.ryanandbrian.com/texas-consumer-protection-notice',
              className: 'on-susak-footer-notice',
              data: 'Texas Real Estate Commission Consumer Protection Notice',
              style: { color: 'white' },
            },
            {
              id: 'footer-two-info',
              type: 'heading',
              url: 'https://drive.google.com/file/d/1nnrbJKeD2QWujLNfhsKYgDv2t0evnwm6/view?usp=sharing',
              className: 'on-susak-footer-info pb-2',
              data: 'Information About Brokerage Services',
              style: { color: 'white' },
            },
          ],
        },
      ],
    },
    {
      id: 'on-susak-footer-third',
      display: true,
      className: 'on-susak-footer-section-last',
      sectionClassName: 'on-susak-footer-section-container-last',
      columns: [
        {
          id: 'disclaimer-column',
          className: 'on-susak-footer-row-privacy on-susak-footer-boom',
          style: {},
          items: [
            {
              id: 'boomtown-logo',
              type: 'image',
              className: 'footer-link-item-first ps-0',
              alt: 'logo',
              url: 'https://si-homelight.s3.amazonaws.com/sites/susak/logo-footer-boomtown.png',
              linkUrl:
                'https://boomtownroi.com/?utm_campaign=ryanandbrian.com&utm_medium=bt_client_site_referral&utm_source=client_sites',
              style: { height: '19px', paddingRight: '20px' },
            },
            {
              id: 'footer-2022',
              type: 'heading',
              className: 'footer-link-item-first',
              data: '© 2022',
              url: 'https://boomtownroi.com/?utm_campaign=ryanandbrian.com&utm_medium=bt_client_site_referral&utm_source=client_sites',
            },
          ],
        },
        {
          id: 'disclaimer-column-two',
          className: 'on-susak-footer-row-privacy align-left on-susak-footer-links',
          style: {},
          items: [
            {
              id: 'footer-terms',
              type: 'heading',
              className: 'on-susak-footer-three-items',
              data: 'Terms of Use',
              url: 'https://www.ryanandbrian.com/terms',
            },
            {
              id: 'footer-privacy',
              type: 'heading',
              className: 'on-susak-footer-three-items',
              data: 'Privacy Policy',
              url: 'https://www.ryanandbrian.com/privacy/',
            },
            {
              id: 'footer-access',
              type: 'heading',
              className: 'on-susak-footer-three-items',
              data: 'Accessibility',
              url: 'https://www.ryanandbrian.com/accessibility/',
            },
            {
              id: 'footer-DMCA',
              type: 'heading',
              className: 'on-susak-footer-three-items',
              data: 'DMCA',
              url: 'https://www.ryanandbrian.com/dmca/',
            },
            {
              id: 'footer-sitemap',
              type: 'heading',
              className: 'on-susak-footer-three-items',
              data: 'Listings Sitemap',
              url: 'https://www.ryanandbrian.com/sitemap/',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'The Ryan & Brian Real Estate Team',
    agentDescription: 'Top agents in McAllen, Texas',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/susak/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/susak/hero-bg.jpg',
  },
};
